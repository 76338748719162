import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import emailjs from "@emailjs/browser";

import "./Contact.css";
import "react-toastify/dist/ReactToastify.css";

const Contact = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!name || !email || !subject || !message) {
      return toast.error("Please complete the form above");
    }

    setLoading(true);

    const data = {
      name,
      email,
      subject,
      message,
    };

    emailjs
      .send("service_hznzkvb", "template_arks2pl", data, "j6sb-B3bWAXahc1ZW")
      .then(
        (result) => {
          setName("");
          setEmail("");
          setSubject("");
          setMessage("");
          setLoading(false);
          toast.success(`Successfully sent email.`);
        },
        (error) => {
          setLoading(false);
          console.log(error);
          toast.error(error.text);
        }
      );
  };

  return (
    <section className="contact container section" id="contact">
      <h2 className="section__title">Get In Touch</h2>

      <div className="contact__container grid">
        <div className="contact__info">
          <h3 className="contact__title">Let's talk about everything!</h3>
          <p className="contact__details flex flex-col">
            Don't like forms? Send me an email
            <div>
              (
              <a href="mailto:rs3209428@gmail.com" className="text-[#ff4d61]">
                {"rs3209428@gmail.com"}
              </a>
              ) . 👋
            </div>
          </p>
        </div>

        <form onSubmit={submitHandler} className="contact__form flex flex-col">
          <div className="contact__form-group">
            <div className="contact__form-div">
              <input
                type="text"
                className="contact__form-input"
                placeholder="Insert your name"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
            </div>

            <div className="contact__form-div">
              <input
                type="email"
                className="contact__form-input"
                placeholder="Insert your email"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
            </div>
          </div>

          <div className="contact__form-div">
            <input
              type="text"
              className="contact__form-input"
              placeholder="Insert your subject"
              onChange={(e) => setSubject(e.target.value)}
              value={subject}
            />
          </div>

          <div className="contact__form-div contact__form-area">
            <textarea
              name=""
              id=""
              cols="30"
              rows="10"
              className="contact__form-input"
              placeholder="Write your message"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            ></textarea>
          </div>

          <button
            disabled={loading}
            type="submit"
            className="btn !disabled:cursor-not-allowed"
          >
            {loading ? "Sending..." : "Send Message"}
          </button>
        </form>
        <ToastContainer position="bottom-right" theme={props.theme} />
      </div>
    </section>
  );
};

export default Contact;
