import Work1 from "../../assets/craftConnectImg.jpeg";
import Work2 from "../../assets/chronoTalk.jpeg";
import Work3 from "../../assets/chess.jpeg";

const Menu = [
  {
    id: 1,
    image: Work1,
    title: "CraftConnect",
    category: ["Frontend", "React", "Backend"],
    url: "https://craft-connect-app.vercel.app/",
    repositoryUrl: "https://github.com/RksRocks/craftConnect",
  },
  {
    id: 2,
    image: Work2,
    title: "Chrono Talk",
    category: ["Frontend", "React", "Socket.io", "Backend"],
    url: "https://chrono-talk-app.vercel.app/",
    repositoryUrl: "https://github.com/RksRocks/ChronoTalk",
  },
  {
    id: 3,
    image: Work3,
    title: "chess.com",
    category: ["Frontend", "Socket.io"],
    url: "https://chess-com-isdm.onrender.com",
    repositoryUrl: "https://github.com/RksRocks/chess.com",
  },
];

export default Menu;
