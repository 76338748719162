import React, { useState } from "react";
import "./Portfolio.css";

import Menu from "./Menu";
import { RiGithubLine, RiLink } from "react-icons/ri";

import { motion } from "framer-motion";

const Portfolio = () => {
  const [items, setItems] = useState(Menu);
  const [activeFilter, setActiveFilter] = useState(0);
  const filterItems = (categoryItem) => {
    const updatedItems = Menu.filter((curElem) => {
      return curElem.category.includes(categoryItem);
    });

    setItems(updatedItems);
  };

  return (
    <section className="portfolio container section" id="portfolio">
      <h2 className="section__title">Projects</h2>

      <div className="portfolio__filters">
        <span
          className={
            activeFilter === 0
              ? "portfolio__item portfolio__item-active"
              : "portfolio__item"
          }
          onClick={() => {
            setItems(Menu);
            setActiveFilter(0);
          }}
        >
          All
        </span>
        <span
          className={
            activeFilter === 1
              ? "portfolio__item portfolio__item-active"
              : "portfolio__item"
          }
          onClick={() => {
            filterItems("Frontend");
            setActiveFilter(1);
          }}
        >
          Frontend
        </span>
        <span
          className={
            activeFilter === 2
              ? "portfolio__item portfolio__item-active"
              : "portfolio__item"
          }
          onClick={() => {
            filterItems("Backend");
            setActiveFilter(2);
          }}
        >
          Backend
        </span>
        <span
          className={
            activeFilter === 3
              ? "portfolio__item portfolio__item-active"
              : "portfolio__item"
          }
          onClick={() => {
            filterItems("React");
            setActiveFilter(3);
          }}
        >
          React
        </span>
        <span
          className={
            activeFilter === 4
              ? "portfolio__item portfolio__item-active"
              : "portfolio__item"
          }
          onClick={() => {
            filterItems("Socket.io");
            setActiveFilter(4);
          }}
        >
          Socket.io
        </span>
      </div>

      <div className="portfolio__container grid">
        {items.map((elem) => {
          const { id, image, title, category, url, repositoryUrl } = elem;

          return (
            <motion.div
              layout
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="portfolio__card h-[200px] relative"
              key={id}
            >
              <div className="portfolio__thumbnail h-full w-full">
                <img
                  src={image}
                  alt=""
                  className="portfolio__img object-left-top h-full  w-full"
                  //   height="267"
                />
                <div className="portfolio__mask"></div>
              </div>
              <div className="absolute top-0 left-0 w-full">
                <span className="portfolio__category">
                  {category.join(", ")}
                </span>
                <h3 className="portfolio__title">{title}</h3>
                <div className="relative">
                  <a
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    className="portfolio__button flex justify-center items-center"
                  >
                    <RiLink className="portfolio__button-icon self-center  w-5 h-5" />
                  </a>
                  {!!repositoryUrl && (
                    <a
                      href={repositoryUrl}
                      target="_blank"
                      rel="noreferrer"
                      className="portfolio__github-button flex justify-center items-center"
                    >
                      <RiGithubLine className="portfolio__button-icon self-center w-6 h-6" />
                    </a>
                  )}
                </div>
              </div>
            </motion.div>
          );
        })}
      </div>
    </section>
  );
};

export default Portfolio;
